@import url("https://fonts.googleapis.com/css2?family=Handjet:wght@500&family=Luckiest+Guy&display=swap");

.text-handjet {
  font-family: "Handjet", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "ELGR" 1, "ELSH" 2;
}

.text-luckiest-guy-regular {
  font-family: "Luckiest Guy", cursive;
  font-weight: 400;
  font-style: normal;
}

.text-logo {
  -webkit-text-stroke: 1px oklch(var(--ac));
  color: oklch(var(--in));
}
