@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --nav-header-height: 60px;
  --content-height-with-nav-header-offset: calc(
    100vh - var(--nav-header-height)
  );
  --side-menu-width: 55vw;

  --screenshot-frame-width: 120px;
  --screenshot-frame-height: calc(var(--screenshot-frame-width) / 0.461);

  --z-index-toast: 100;
  --z-index-menu: 20;
}
